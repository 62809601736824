<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationObserver ref="observer">
      <v-layout column wrap>
        <v-card-title class="font-weight-black pl-3" style="font-size:32px">
          {{ $t('link.editSendTemplateTextManagement') }}
        </v-card-title>
        <!--  メールアドレス -->
        <v-row class="ml-8">
          <p class="title my-auto font-weight-black">
            {{ $t('label.sendTemplateTextManagement.patternType') }}
            <v-chip
              class="ma-2 font-weight-black"
              color="next"
              dark
              label
              small
              >{{ $t('label.required') }}</v-chip
            >
          </p>
        </v-row>

        <v-row class="ml-8">
          <v-flex>
            <ValidationProvider
              :rules="`required`"
              immediate
              v-slot="{ errors }"
            >
              <v-select
                v-model="messageType"
                :items="messageTypes"
                item-text="name"
                required
                return-object
              />
              <ValidationError class="ma-0" :error="errors[0]" />
            </ValidationProvider>
          </v-flex>
        </v-row>

        <v-row class="ml-8">
          <p class="title my-auto font-weight-black">
            {{ $t('label.sendTemplateTextManagement.message') }}
            <v-chip
              class="ma-2 font-weight-black"
              color="next"
              dark
              label
              small
              >{{ $t('label.required') }}</v-chip
            >
          </p>
        </v-row>

        <v-row class="ml-8">
          <p class="my-auto font-weight-regular">
            {{ $t('description.editSendTemplateTextManagement.message') }}
          </p>
        </v-row>

        <v-row class="ml-8" v-if="!isLoading && sendTemplateTextManagement">
          <v-flex>
            <ValidationProvider
              :rules="
                `required|requiredContractUrl|unusableModificationContent|max:${maxLengthMessage}`
              "
              immediate
              v-slot="{ errors }"
            >
              <v-textarea
                rows="20"
                outlined
                v-model="sendTemplateTextManagement.message"
                required
                :counter="maxLengthMessage"
              ></v-textarea>
              <ValidationError class="ma-0" :error="errors[0]" />
            </ValidationProvider>
          </v-flex>
        </v-row>

        <template v-if="messageType.senderType === 'sms'">
          <v-card-text class="font-weight-black text-right pr-2 pb-1 mx-auto">
            {{ $t('description.messageContent.sendMessageFooter.sms') }}
          </v-card-text>
        </template>

        <template v-if="messageType.senderType === 'mail'">
          <v-card-text class="font-weight-black text-right pr-2 pb-1 mx-auto">
            {{ $t('description.messageContent.sendMessageFooter.mail') }}
          </v-card-text>
        </template>

        <v-card-text class="text-right pt-0 pr-2 pb-1 mx-auto">
          {{ $t('description.messageContent.sendMessageFooter.contractUrl') }}
        </v-card-text>

        <v-row class="ml-8 mt-4">
          <v-btn
            class="title mr-5"
            color="back"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickBackHome()"
            >{{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            class="title ml-5"
            color="next"
            width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickUpdate()"
            >{{ $t('button.register') }}
          </v-btn>
        </v-row>
        <CompletedDialog ref="completedDialog"></CompletedDialog>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
      </v-layout>
    </ValidationObserver>
  </v-container>
</template>

<script>
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';
import { MaxLength } from '@/lib/const';
import {
  getSendTemplateTextManagements,
  putSendTemplateTextManagement,
} from '@/apis/agency/sendTemplateTextManagements.js';

export default {
  name: 'EditSendTemplateTextManagement',
  components: {
    CompletedDialog,
    ErrorDialog,
    ValidationProvider,
    ValidationObserver,
    ValidationError,
  },
  data() {
    return {
      isLoading: true,
      sendTemplateTextManagements: [],
      sendTemplateTextManagement: {},
      // デフォルト表示
      messageType: {
        name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.smsProposePatern1'),
        senderType: 'sms',
        sceneType: 'propose',
        pattern: 1,
      },
      messageTypes: [
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.smsProposePatern1'),
          senderType: 'sms',
          sceneType: 'propose',
          pattern: 1,
        },
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.smsProposePatern2'),
          senderType: 'sms',
          sceneType: 'propose',
          pattern: 2,
        },
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.smsConcludePatern1'),
          senderType: 'sms',
          sceneType: 'conclude',
          pattern: 1,
        },
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.smsConcludePatern2'),
          senderType: 'sms',
          sceneType: 'conclude',
          pattern: 2,
        },
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.mailProposePatern1'),
          senderType: 'mail',
          sceneType: 'propose',
          pattern: 1,
        },
        {
          name: this.$t('label.sendTemplateTextManagement.selectBoxTitle.mailConcludePatern1'),
          senderType: 'mail',
          sceneType: 'conclude',
          pattern: 1,
        },
      ],
    };
  },
  watch: {
    messageType: {
      handler(messageType) {
        this.$set(
          this,
          'sendTemplateTextManagement',
          this.getSendTemplateTextManagement(messageType)
        );
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    maxLengthMessage() {
      switch (this.sendTemplateTextManagement.senderType) {
        case 'sms':
          return MaxLength.SmsMessageBody;
        case 'mail':
          return MaxLength.EmailMessageBody;
        default:
          throw 'invalid senderType';
      }
    },
  },

  async mounted() {
    // ユーザーの取得
    this.$set(
      this,
      'sendTemplateTextManagements',
      await this.fetchSendTemplateTextManagements()
    );
    this.$set(
      this,
      'sendTemplateTextManagement',
      this.getSendTemplateTextManagement(this.messageType)
    );
  },

  methods: {
    getSendTemplateTextManagement(messageType) {
      return this.sendTemplateTextManagements.find(
        sendTemplateTextManagement =>
          sendTemplateTextManagement.senderType === messageType.senderType &&
          sendTemplateTextManagement.sceneType === messageType.sceneType &&
          sendTemplateTextManagement.pattern === messageType.pattern
      );
    },

    async fetchSendTemplateTextManagements() {
      const SendTemplateTextManagements = await getSendTemplateTextManagements();
      this.$set(this, 'isLoading', false);
      return SendTemplateTextManagements;
    },

    onClickBackHome() {
      this.$router.push('/Home');
    },
    async onClickUpdate() {
      // バリデーションチェック
      if (!await this.$refs.observer.validate()) return;

      // DB更新
      const result = await putSendTemplateTextManagement(
        this.sendTemplateTextManagement
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.sendTemplateTextManagementUpdateError'),
          this.$t('error.sendTemplateTextManagementBadRequest')
        );
      });

      if (!result) return;

      this.$refs.completedDialog.open(
        this.$t('title.agency.sendTemplateTextManagementUpdateResult'),
        this.$t('success.sendTemplateTextManagementUpdated')
      );
    },
  },
};
</script>
